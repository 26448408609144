@import url('https://fonts.googleapis.com/css?family=Montserrat:');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;

  font-family: Montserrat, sans-serif;
}
